export default function useDownloadFile() {
  function downloadFile(value: Blob, name: string) {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(value)
    link.download = name
    link.click()
  }

  return {
    downloadFile,
  }
}

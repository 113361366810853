import revive_payload_client_o7YHJuMGSh from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a55xr6BHEa from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aygiF1ENhw from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RFDxBJYsSc from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_mgCovKuv0N from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_SfKk2iIiMl from "/builds/ldseating/nido/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.4.5_vue@3.4.29_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/ldseating/nido/.nuxt/components.plugin.mjs";
import prefetch_client_t28nDkW6kz from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_BG0txTF9Yy from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_xwur5libau6yyasogc3j6iytqy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_GRSPX2l9Ej from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_xwur5libau6yyasogc3j6iytqy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_gplWfnVcMI from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.17.0_focus-trap@7.5.4_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_xwur5libau6yyasogc3j6iytqy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_6k3Azp2mCW from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_nFX2ksDGeR from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.29_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_BHjtgdCO7H from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/builds/ldseating/nido/plugins/sentry.client.ts";
export default [
  revive_payload_client_o7YHJuMGSh,
  unhead_a55xr6BHEa,
  router_aygiF1ENhw,
  payload_client_RFDxBJYsSc,
  check_outdated_build_client_mgCovKuv0N,
  plugin_vue3_SfKk2iIiMl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t28nDkW6kz,
  slideovers_BG0txTF9Yy,
  modals_GRSPX2l9Ej,
  colors_gplWfnVcMI,
  plugin_client_6k3Azp2mCW,
  i18n_nFX2ksDGeR,
  chunk_reload_client_BHjtgdCO7H,
  sentry_client_shVUlIjFLk
]
import useDownloadFile from './useDownloadFile'
import { useUser } from '~/stores/user'
import { NidoSerieId } from '~/service/experience/world/nido/enum'
import type {
  ProductPrice,
  ProductAccessoryPrices,
  CountryPagedResponse,
  RegionPagedResponse,
  User,
  Currency,
  AccessTokenResponse,
  SurfaceCollectionCategoryWithCollections,
  ProductPrices,
} from '~/types/api'
import type {
  Configuration,
  InsertOrder,
  Order,
  OrderStateEnum,
} from '~/db/schema'

export default function useApi() {
  const userStore = useUser()
  const { downloadFile } = useDownloadFile()
  const errorToast = useErrorToast()
  const t = useNuxtApp().$i18n.t
  const { $sentrySetUser } = useNuxtApp()

  const user = computed(() => {
    return userStore.user
  })

  async function createOrder(
    order: InsertOrder,
    image: string,
    path: string,
    locale: string,
    version: number | null,
    projectJson: string
  ) {
    const { data, error } = await useFetch('/api/order', {
      method: 'POST',
      body: {
        order,
        image,
        path,
        locale,
        version,
        projectJson,
      },
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    if (!data.value) {
      errorToast.add(t('error.somethingWentWrong'))
    }

    return data.value
  }

  async function createConfiguration(
    projectJson: string,
    userId: number | null,
    previousConfigurationVersion: number | null,
    orderId: string | null,
    image: string
  ) {
    const { data, error } = await useFetch('/api/order/save', {
      method: 'POST',
      body: {
        projectJson,
        userId,
        previousConfigurationVersion,
        orderId,
        image,
      },
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    if (!data.value) {
      errorToast.add(t('error.somethingWentWrong'))
    }

    return data.value
  }

  async function getOrders(
    page: number,
    size = 10,
    state: OrderStateEnum | null
  ) {
    const { data: orders, error } = await useFetch<{
      totalPage: number
      size: number
      ordersList: Order[]
    }>('/api/orders', {
      params: {
        page,
        size,
        state,
      },
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    if (orders.value) {
      return orders.value
    } else {
      return {
        totalPage: 0,
        size: 0,
        ordersList: [],
      }
    }
  }

  async function getSurfaces() {
    const { data: surfaces, error } =
      await useFetch<SurfaceCollectionCategoryWithCollections>(
        '/api/surfaces',
        {
          params: {
            serieId: NidoSerieId,
          },
        }
      )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return surfaces.value || {}
  }

  async function getCountries() {
    const { data: countries, error } = await useFetch<CountryPagedResponse>(
      '/api/countries'
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return countries.value?.items
  }

  async function getRegions() {
    const { data: regions, error } = await useFetch<RegionPagedResponse>(
      '/api/regions'
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return regions.value?.items
  }

  async function getSalesmanForCountry(countryIdVal: number) {
    const { data: salesman, error } = await useFetch<User>(
      '/api/salesman/country',
      {
        params: {
          countryId: countryIdVal,
        },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return salesman.value
  }

  async function getSalesmanForRegion(
    countryIdVal: number,
    regionIdVal: number
  ) {
    const { data: salesman, error } = await useFetch<User>(
      '/api/salesman/region',
      {
        params: {
          countryId: countryIdVal,
          regionId: regionIdVal,
        },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return salesman.value
  }

  async function getSalesmanForCurrentUser() {
    const { data: salesman, error } = await useFetch<User>(
      '/api/salesman/user',
      {
        params: {
          userId: user.value?.uid,
        },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return salesman.value
  }

  async function getPrices() {
    const prices: {
      nid: number
      translationOfNid?: number | null
      prices: ProductPrice[]
    }[] = []
    const customerGroupId = userStore.user?.customerGroup?.nid ?? null
    const promises: Promise<any>[] = []

    promises.push(
      useFetch<ProductAccessoryPrices[]>('/api/accessoryPrices', {
        params: {
          productLineId: NidoSerieId,
          customerGroupId,
        },
      }).then(({ data, error }) => {
        if (error.value) {
          errorToast.add(
            t(error.value.statusMessage || 'error.somethingWentWrong')
          )
          return
        }

        if (!data || !data.value) return

        prices.push(...data.value)
      })
    )

    promises.push(
      useFetch<ProductPrices[]>('/api/prices', {
        params: {
          productLineId: NidoSerieId,
          customerGroupId,
        },
      }).then(({ data, error }) => {
        if (error.value) {
          errorToast.add(
            t(error.value.statusMessage || 'error.somethingWentWrong')
          )
        }

        if (!data.value) return

        prices.push(...data.value)
      })
    )

    await Promise.all(promises)

    return prices
  }

  async function getOrder(id: string): Promise<Order | null> {
    const { data: order, error } = await useFetch<Order>(`/api/order/${id}`)

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return order.value
  }

  async function getConfiguration(
    id: number,
    version: number | null
  ): Promise<Configuration | null> {
    const { data: configuration, error } = await useFetch<Configuration>(
      `/api/configuration/${id}`,
      {
        params: {
          version,
        },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return configuration.value
  }

  async function getConfigurationVersions(orderId: string): Promise<number[]> {
    const { data: versions, error } = await useFetch<number[]>(
      '/api/configuration/versions',
      {
        params: {
          orderId,
        },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    if (!versions.value) {
      return []
    }

    return versions.value
  }

  async function getOrderImage(orderId: string, version: number) {
    const { data: orderImage, error } = await useFetch<string>(
      `/api/order/image/${orderId}`,
      {
        params: { version },
      }
    )

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    return orderImage
  }

  async function downloadPdf(orderId: string, path: string) {
    const { data: pdf, error } = await useFetch<Blob>('/api/order/pdf', {
      responseType: 'blob',
      params: {
        orderId,
        path,
      },
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
    }

    if (pdf.value) {
      downloadFile(pdf.value, `nido-order-${orderId}.pdf`)
    }
  }

  async function login(email: string, password: string) {
    const { data: userData, error } = await useFetch<{
      loginResponse: AccessTokenResponse
      userResponse: User
    }>('/api/login', {
      method: 'POST',
      body: {
        email,
        password,
      },
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
      throw createError({
        ...error.value,
      })
    } else if (userData.value) {
      const userResponse = userData.value.userResponse
      userStore.setUser(userResponse, userData.value.loginResponse)

      $sentrySetUser({
        id: userResponse.uid,
        email: userResponse.email,
        username: userResponse.firstName ?? '' + userResponse.lastName ?? '',
      })
    }
  }

  async function logout() {
    const { data: userData, error } = await useFetch<User>('/api/logout', {
      method: 'POST',
    })

    if (error.value) {
      errorToast.add(t(error.value.statusMessage || 'error.somethingWentWrong'))
      throw createError({
        ...error.value,
      })
    } else {
      userStore.logout()
    }
  }

  async function validateUser() {
    const { data: userData, error } = await useFetch<{
      userResponse: User
      accessToken: string
    }>('/api/validateUser')

    if (error.value) {
      return null
    }

    return userData.value
  }

  async function getCustomerGroupCurrency(customerGroupId: number) {
    const { data: currency } = await useFetch<Currency>(
      `/api/customerGroups/${customerGroupId}/currency`
    )
    return currency.value
  }

  return {
    createOrder,
    createConfiguration,
    getOrders,
    getPrices,
    getOrder,
    getConfiguration,
    getConfigurationVersions,
    getOrderImage,
    getSurfaces,
    downloadPdf,
    login,
    logout,
    validateUser,
    getCountries,
    getRegions,
    getSalesmanForCountry,
    getSalesmanForRegion,
    getSalesmanForCurrentUser,
    getCustomerGroupCurrency,
  }
}

import { default as indexrRaEbngHyKMeta } from "/builds/ldseating/nido/pages/index.vue?macro=true";
import { default as concepts57sga17IkAMeta } from "/builds/ldseating/nido/pages/concepts.vue?macro=true";
import { default as configuratorISdgE3CUgQMeta } from "/builds/ldseating/nido/pages/configurator.vue?macro=true";
import { default as _91id_93EKO6uvyIxYMeta } from "/builds/ldseating/nido/pages/order/[id].vue?macro=true";
import { default as ordersSO7JMec0F9Meta } from "/builds/ldseating/nido/pages/orders.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/ldseating/nido/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "concepts___cs",
    path: "/cs/concepts",
    meta: concepts57sga17IkAMeta || {},
    component: () => import("/builds/ldseating/nido/pages/concepts.vue").then(m => m.default || m)
  },
  {
    name: "concepts___en",
    path: "/en/concepts",
    meta: concepts57sga17IkAMeta || {},
    component: () => import("/builds/ldseating/nido/pages/concepts.vue").then(m => m.default || m)
  },
  {
    name: "concepts___de",
    path: "/de/concepts",
    meta: concepts57sga17IkAMeta || {},
    component: () => import("/builds/ldseating/nido/pages/concepts.vue").then(m => m.default || m)
  },
  {
    name: "concepts___fr",
    path: "/fr/concepts",
    meta: concepts57sga17IkAMeta || {},
    component: () => import("/builds/ldseating/nido/pages/concepts.vue").then(m => m.default || m)
  },
  {
    name: "configurator___cs",
    path: "/cs/konfigurator",
    meta: configuratorISdgE3CUgQMeta || {},
    component: () => import("/builds/ldseating/nido/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___en",
    path: "/en/configurator",
    meta: configuratorISdgE3CUgQMeta || {},
    component: () => import("/builds/ldseating/nido/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___de",
    path: "/de/konfigurator",
    meta: configuratorISdgE3CUgQMeta || {},
    component: () => import("/builds/ldseating/nido/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___fr",
    path: "/fr/configurateur",
    meta: configuratorISdgE3CUgQMeta || {},
    component: () => import("/builds/ldseating/nido/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/builds/ldseating/nido/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/ldseating/nido/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/builds/ldseating/nido/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/builds/ldseating/nido/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "order-id___cs",
    path: "/cs/poptavka/:id()",
    meta: _91id_93EKO6uvyIxYMeta || {},
    component: () => import("/builds/ldseating/nido/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-id___en",
    path: "/en/request/:id()",
    meta: _91id_93EKO6uvyIxYMeta || {},
    component: () => import("/builds/ldseating/nido/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-id___de",
    path: "/de/anfrage/:id()",
    meta: _91id_93EKO6uvyIxYMeta || {},
    component: () => import("/builds/ldseating/nido/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-id___fr",
    path: "/fr/demande/:id()",
    meta: _91id_93EKO6uvyIxYMeta || {},
    component: () => import("/builds/ldseating/nido/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders___cs",
    path: "/cs/poptavky",
    meta: ordersSO7JMec0F9Meta || {},
    component: () => import("/builds/ldseating/nido/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/en/requests",
    meta: ordersSO7JMec0F9Meta || {},
    component: () => import("/builds/ldseating/nido/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___de",
    path: "/de/anfragen",
    meta: ordersSO7JMec0F9Meta || {},
    component: () => import("/builds/ldseating/nido/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders___fr",
    path: "/fr/demandes",
    meta: ordersSO7JMec0F9Meta || {},
    component: () => import("/builds/ldseating/nido/pages/orders.vue").then(m => m.default || m)
  }
]
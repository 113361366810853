interface SEOInformation {
  title: string
  description: string
  ogImage?: string
}

export default ({ title, description, ogImage = '' }: SEOInformation) => {
  // TODO: Change to desired url.
  const config = useRuntimeConfig()

  const url = config.public.url
  const route = useRoute()
  useHead({
    title,
    meta: [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: url },
      { property: 'og:description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:image', content: url + ogImage },
    ],
    link: [
      { rel: 'canonical', href: url + route.path }
    ]
  })
}

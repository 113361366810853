import routerOptions0 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.19.36_@unocss+reset_l2ncchos76i7fhcpw65g4gmctq/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/ldseating/nido/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications />
  </div>
</template>

<script setup>
const { t } = useI18n()

useSEOHead({
  title: 'Nido | LD Seating',
  description: t('meta.description'),
  ogImage:
    '/og-image/nido-og.jpg',
})
</script>

<style>
/* width */
.scrollable::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  background: #a1a1a1;
  border-radius: 25px;
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 25px;
}

/* Handle on hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

.scrollable::-webkit-scrollbar-button:start:increment {
  width: 5%;
  display: block;
  background: transparent;
}

.scrollable::-webkit-scrollbar-button:end:increment {
  width: 5%;
  display: block;
  background: transparent;
}

.custom-scroll {
  /* width */
  ::-webkit-scrollbar {
    height: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 25px;
    background-color: #aaaaaa;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #817c6b;
  }

  ::-webkit-scrollbar-button:start:increment {
    width: 5px;
    display: block;
    background: transparent;
  }

  ::-webkit-scrollbar-button:end:increment {
    width: 5px;
    display: block;
    background: transparent;
  }
}

@media print {
  table {
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
</style>

import { defineStore } from 'pinia'
import type { AccessTokenResponse, Currency, User } from '~/types/api'

export const useUser = defineStore('user', () => {
  const api = useApi()

  const user = ref<User>()
  const accessToken = ref<string>()

  const isLoggedIn = computed(() => {
    return !!accessToken.value
  })

  const canSeePrices = computed(() => {
    if (accessToken.value && user.value) {
      return (
        user.value.customerGroup !== null &&
        user.value.customerGroup !== undefined
      )
    }
    return false
  })

  async function revalidateUser() {
    return await api.validateUser()
  }

  function setUser(userData: User, accessTokenResponse: AccessTokenResponse) {
    user.value = userData
    accessToken.value = accessTokenResponse.accessToken
  }

  function logout() {
    user.value = undefined
    accessToken.value = undefined
  }

  let currencyPromise: Promise<Currency | null>
  async function getUserCurrency(): Promise<Currency | null> {
    const customerGroupId = user.value?.customerGroup?.nid ?? null
    if (customerGroupId === null) {
      return null
    }
    if (currencyPromise) {
      return currencyPromise
    }

    currencyPromise = api.getCustomerGroupCurrency(customerGroupId)
    return currencyPromise
  }

  let initPromise: Promise<void>
  async function init() {
    if (initPromise) {
      return initPromise
    }
    initPromise = new Promise(async (resolve) => {
      api.validateUser().then((res) => {
        if (res) {
          user.value = res.userResponse
          accessToken.value = res.accessToken
        }
        resolve()
      })
    })
    return initPromise
  }

  return {
    user,
    setUser,
    isLoggedIn,
    logout,
    canSeePrices,
    revalidateUser,
    init,
    getUserCurrency,
  }
})

export default function useErrorToast() {
  const toast = useToast()

  function add(message: string) {
    if (process.server) return

    toast.add({
      closeButton: {
        icon: 'i-heroicons-x-mark-20-solid',
        variant: 'link',
        padded: true,
        size: '2xs',
        ui: { rounded: 'rounded-full !text-white hover:!text-neutral-300' },
      },
      title: message,
      color: 'red',
      ui: {
        shadow: 'shadow-none !bg-black',
        ring: '',
        title: 'text-sm font-bold !text-white text-pragmatica-extended',
        description: 'mt-1 text-sm leading-4 opacity-90 text-gray-200',
      },
    })
  }

  return {
    add,
  }
}
